import UIKit from 'uikit/dist/js/uikit.min.js';
import UIKitIcons from 'uikit/dist/js/uikit-icons.js';
import './main.scss';

UIKit.use(UIKitIcons);

document.onreadystatechange = function () {
    if (document.readyState == "interactive") {
        setTimeout(() => {
            UIKit.util.on('li[class*="ot-letter"]', 'inview', function (event) {
                event.target.classList.add('active');
                setTimeout(() => {
                    event.target.classList.remove('active');
                }, 200);
            });
        }, 1000)
    }
}

function includeHTML() {
    var z, i, elmnt, file, xhttp;
    /* Loop through a collection of all HTML elements: */
    z = document.getElementsByTagName("*");
    for (i = 0; i < z.length; i++) {
        elmnt = z[i];
        /*search for elements with a certain atrribute:*/
        file = elmnt.getAttribute("include-html");
        if (file) {
            /* Make an HTTP request using the attribute value as the file name: */
            xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = function() {
                if (this.readyState == 4) {
                    if (this.status == 200) {elmnt.innerHTML = this.responseText;}
                    if (this.status == 404) {elmnt.innerHTML = "Page not found.";}
                    /* Remove the attribute, and call this function once more: */
                    elmnt.removeAttribute("include-html");
                    includeHTML();
                }
            }
            xhttp.open("GET", file, true);
            xhttp.send();
            /* Exit the function: */
            return;
        }
    }
}

includeHTML();
